import React, { MouseEvent, useState, useRef, useEffect, RefObject } from 'react'
import { AspectRatio, Box, Flex as CkFlex, HStack, Link as CkLink } from '@chakra-ui/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { EPlaceTypeEnum } from 'enums/roomDefaultValue'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { useScrollingEvent } from 'hooks/useScrollingEvent'
import { useStores } from 'hooks/useStores'
import get from 'lodash/get'
import identity from 'lodash/identity'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { NextRouter, useRouter } from 'next/router'
import Icon from 'components/Icon'
import Image from 'components/Image'
import MultiCarousel from 'components/MultiCarousel'
import { defaultSlide } from 'components/MultiCarousel/constants'
import { IconWrapper } from 'components/pages/DetailPage/components/RoomCard/roomCard.styles'
import { ICarouseItem } from 'interfaces/listing'
import { IProperty } from 'interfaces/property'
import { ITag } from 'interfaces/tag'
import routes from 'routes'
import { formatAvailableDate, getCensorAddress, getDefaultSlide, getValidArray, getValidEnding } from 'utils/common'
import {
  RoomCardBioContainer,
  RoomCardBioFooter,
  RoomCardBioText,
  RoomCardBioTitle,
  RoomCardContainer,
  RoomCardWrapper,
  Flex,
  RoomCardViewHouseButton,
  RoomCardViewText,
  RoomCardButtonClosePopup
} from './roomCard.styles'
import { getCarouselTransformValue } from './utils'
import styles from './roomCard.module.scss'

dayjs.extend(advancedFormat)

interface IRoomCardProps extends IProperty {
  popup?: boolean
  images: ICarouseItem[]
  changePosition: (lat: number, lng: number, zoomLevel: number) => void
  closePopupOnClick?: (event: MouseEvent<HTMLElement>) => void
  defaultImageUrl?: string
}

const RoomCard = (props: IRoomCardProps) => {
  const {
    id,
    lat,
    lng,
    address = '',
    unitCount = 0,
    buildiumPropertyId,
    availableDate,
    lowestUnitPrice,
    images,
    popup,
    defaultImageUrl,
    changePosition,
    closePopupOnClick,
    placeType,
    numberOfBedrooms = 0,
    numberOfBathrooms = 0,
    tags
  } = props
  const router: NextRouter = useRouter()
  const { query } = router
  const { listingStore } = useStores()
  const { selectedHouseId } = listingStore
  const shortAddress: string = getCensorAddress(String(address), buildiumPropertyId)
  const isDesktop: boolean = useBreakPoint(EBreakPoint.LG)
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const validImages: ICarouseItem[] = !isEmpty(images)
    ? getValidArray(images)
    : getDefaultSlide(defaultSlide, defaultImageUrl ?? defaultSlide[0]?.url)
  const isEntireHouse: boolean = placeType === EPlaceTypeEnum.ENTIRE_HOUSE
  const bathrooms: number = numberOfBathrooms === 0 ? 1 : numberOfBathrooms
  const bedrooms: number = numberOfBedrooms === 0 ? 1 : numberOfBedrooms
  const isScrolling: boolean = useScrollingEvent()
  const carouselRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)

  function handleSetPosition(): void {
    window && window.open(handleOpenHouseDetail(), '_blank')
    if (!popup) {
      listingStore.setSelectedHouseId(id)
      changePosition(lat, lng, 16)
    }
  }

  function handleOpenHouseDetail(): string {
    const { bathRoomType, price } = query
    const newQuery: string = new URLSearchParams(
      pickBy({ bathRoomType: String(bathRoomType ?? ''), price: String(price ?? '') }, identity)
    ).toString()
    const houseUrl: string = `${routes.detail.value(Number(buildiumPropertyId))}${newQuery ? '?' + newQuery : ''}`
    return houseUrl
  }

  useEffect(() => {
    if (!isDesktop && isScrolling) {
      const carouselStyle: CSSStyleDeclaration = get(carouselRef, 'current.children[0].style')
      const transformValue: string = getCarouselTransformValue(carouselStyle?.transform)
      set(carouselStyle, 'transform', transformValue)
    }
  }, [isScrolling])

  return (
    <RoomCardWrapper>
      <RoomCardContainer onClick={handleSetPosition} popup={popup}>
        <RoomCardButtonClosePopup hidden={!popup} onClick={closePopupOnClick}>
          <Icon iconName="x.svg" width={13} height={13} />
        </RoomCardButtonClosePopup>
        <MultiCarousel
          className={styles.sliderContainer}
          dragging={!isDesktop && !isScrolling}
          setIsHovering={setIsHovering}
          swiping={!isDesktop && !isScrolling}
          showDots={false}
          showArrows={isDesktop && isHovering}
          innerRef={carouselRef}
        >
          {validImages.map((image: ICarouseItem, index: number) => (
            <AspectRatio
              onMouseOver={() => setIsHovering(true)}
              onMouseOut={() => setIsHovering(false)}
              key={`roomcard-item${index}`}
              ratio={16 / 10}
            >
              <Image src={image?.url} alt={image?.name} quality={20} defaultImageUrl={defaultImageUrl} />
            </AspectRatio>
          ))}
        </MultiCarousel>
        <HStack spacing={1} position="absolute" top="5px" right="6px">
          {tags?.map((tag: ITag) => (
            <Box
              key={tag?.id}
              background={`${tag?.color}.500`}
              paddingX={2}
              height="16px"
              alignItems="center"
              textAlign="center"
              fontSize="8px"
              fontWeight="500"
              boxShadow="base"
              color="white"
              zIndex="1"
              borderRadius="10px"
              display="flex"
            >
              {tag?.title}
            </Box>
          ))}
        </HStack>
        <RoomCardBioContainer>
          <CkLink _focus={{ boxShadow: 'none' }} isExternal rel="noopener noreferrer">
            <RoomCardBioTitle noMargin blackColor={popup || selectedHouseId !== id}>
              {shortAddress}
            </RoomCardBioTitle>
          </CkLink>
          <RoomCardBioFooter>
            <Flex justifyContent="space-between">
              <CkFlex>
                {isEntireHouse ? (
                  <>
                    <RoomCardBioText>{getValidEnding(bedrooms, 'Bedroom')}</RoomCardBioText>
                    <IconWrapper>
                      <Icon iconName="dot.svg" width={4} height={4} />
                    </IconWrapper>
                    <RoomCardBioText>{getValidEnding(bathrooms, 'Bath')}</RoomCardBioText>
                  </>
                ) : (
                  <RoomCardBioText>{(unitCount >= 2 ? 2 : 1) + '+ beds available'}</RoomCardBioText>
                )}
              </CkFlex>
              <RoomCardBioText>Frommm</RoomCardBioText>
            </Flex>
            <Flex price>
              <CkFlex>
                <Icon iconName="circle_check.svg" width={16} height={16} />
                <RoomCardBioText bold>Avail. {formatAvailableDate(availableDate!)}</RoomCardBioText>
              </CkFlex>
              <RoomCardBioText price normal>
                ${lowestUnitPrice}+/mo
              </RoomCardBioText>
            </Flex>
          </RoomCardBioFooter>
        </RoomCardBioContainer>
        <HStack spacing={0} width="full" paddingInline={6}>
          <CkLink isExternal rel="noopener noreferrer" width="full">
            <RoomCardViewHouseButton hidden={!popup}>
              <RoomCardViewText>View house</RoomCardViewText>
              <Icon iconName="chevron_right.svg" size={isDesktop ? 16 : 18} />
            </RoomCardViewHouseButton>
          </CkLink>
        </HStack>
      </RoomCardContainer>
    </RoomCardWrapper>
  )
}

export default observer(RoomCard)
